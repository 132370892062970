import { useApi } from '~/composables/useApi'
import type { IUser } from '~/types/models/user.interface'

export default defineNuxtRouteMiddleware(async () => {
	const store = useUserStore()

	try {
		const user: IUser = await useApi('/auth/me')

		if (!import.meta.server) {
			const { connectWallet } = useWalletStore()
			const { $identifyUser, $supportedWallets } = useNuxtApp()
			const connectedWallet = $supportedWallets.find(wallet => wallet.name.toLowerCase() === user.walletName)

			if (!connectedWallet) {
				throw new Error('Wallet not supported')
			}

			await connectWallet(connectedWallet)

			$identifyUser(user as IUser)
		}

		store.login(user as IUser)
	} catch (error) {
		store.logout()
	}
})
